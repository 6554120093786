<template>
    <transition name="modal" v-if="isActive">
        <perfect-scrollbar class="modal-mask">
            <div class="modal-form modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4>
                            <b-icon icon="pencil-square"></b-icon>
                            <span>กรองข้อมูล Excel สำหรับนำเข้าสินค้า</span>
                        </h4>

                        <button class="btn-close" @click="hide()"><b-icon icon="x"></b-icon></button>
                    </div>
                    <div class="modal-body">
                        <div class="search">
                            <div class="form" v-if="form">
                                <div class="row" v-if="form.brands.length > 0">
                                    <div class="col-5 col-md-4">
                                        <div class="input-field mb-1">
                                            <label class="mb-0">ยี่ห้อยาง</label>
                                        </div>
                                    </div>
                                    <div class="col-5 col-md-4">
                                        <div class="input-field mb-1">
                                            <label class="mb-0">รุ่นยาง</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-for="(item, index) in form.brands" :key="index">
                                    <div class="col-5 col-md-4">
                                        <div class="input-field">
                                            <dropdown
                                                v-model="item.brand"
                                                placeholder="ยี่ห้อยางทั้งหมด"
                                                :classes="{ input: [ 'square', 'input-option' ] }"
                                                :options="option.brand[index]"
                                                @change="changeBrand($event, index)">
                                            </dropdown>
                                        </div>
                                    </div>
                                    <div class="col-5 col-md-4">
                                        <div class="input-field">
                                            <dropdown
                                                v-model="item.model"
                                                placeholder="รุ่นทั้งหมด"
                                                :classes="{ input: [ 'square', 'input-option' ] }"
                                                :options="option.model[index]">
                                            </dropdown>
                                        </div>
                                    </div>
                                    <div class="col-2 col-md-4">
                                        <button class="btn-remove" @click="removeFilterBrand(index)">X</button>
                                    </div>
                                </div>

                                <div class="row mt-1 mb-3">
                                    <div class="col-10 col-md-8">
                                        <button class="btn-append" @click="appendFilterBrand()">เพิ่มตัวกรองยี่ห้อยาง</button>
                                    </div>
                                </div>

                                <div class="row mt-3">
                                    <div class="col-12">
                                        <div class="input-field">
                                            <checkbox
                                                v-model="form.isOnlySellerStock">
                                                เลือกเฉพาะสินค้าที่ขายในร้านเท่านั้น
                                            </checkbox>
                                        </div>
                                    </div>
                                </div>

                                <div class="only-seller-stock">
                                    <div class="blocker" v-if="!form.isOnlySellerStock"></div>
                                    <div class="row">
                                        <div class="col-5 col-md-4">
                                            <div class="input-field">
                                                <label>สถานะสินค้า</label>
                                                <dropdown
                                                    v-model="form.status"
                                                    placeholder="สถานะทั้งหมด"
                                                    :classes="{ input: [ 'square', 'input-option' ] }"
                                                    :options="option.status">
                                                </dropdown>
                                            </div>
                                        </div>
                                        <div class="col-5 col-md-4">
                                            <div class="input-field">
                                                <label>ปีผลิต</label>
                                                <dropdown
                                                    v-model="form.year"
                                                    placeholder="ปีผลิตทั้งหมด"
                                                    :classes="{ input: [ 'square', 'input-option' ] }"
                                                    :options="option.year">
                                                </dropdown>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="content-yellow-note mb-2">
                                    <span><b-icon icon="bell" class="icon"></b-icon>
                                    - ถ้าต้องการดึงข้อมูลยางทั้งหมดของร้าน ไม่ต้องเลือกใดๆ สามารถกดปุ่ม "ดาวน์โหลด" ได้เลย
                                    <br />
                                    - หากต้องการดึงเฉพาะรายการที่นำเข้าไป เพื่อนำมาแก้ไขข้อมูล กดเลือก เลือก "ยี่ห้อ-รุ่นยาง" ที่ต้องการ หรือ "เลือกเฉพาะที่ขายในร้านเท่านั้น" แล้วกดปุ่ม "ดาวน์โหลด"</span>
                                </div>

                                <div class="mt-3 text-center">
                                    <button class="btn btn-danger" @click="downloadExcel()"><b-icon icon="cloud-download" class="icon"></b-icon> ดาวน์โหลด</button>
                                    <button class="btn btn-reset" @click="resetForm()">รีเซ็ต</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </perfect-scrollbar>
    </transition>
</template>

<script>
import Helper from '@/services/helper';
import MixinDragScroll from '@/mixins/dragScroll';
import OptionStockStatus from '@/services/staticOption/stockStatus';
import MasterService from '@/services/masterService';

export default {
	mixins: [ MixinDragScroll ],
    data() {
		return {
            isActive: false,
            form: null,
            option: {
                brand: [],
                model: [],
                status: OptionStockStatus.option,
                year: Helper.initStockYearOption()
            },
            brands: []
		};
	},
	props: {
		isDisplay: Boolean
	},
    watch: {
        async isDisplay(value) {
            this.isActive = value;
            this.$root.$emit('rootSetContentScrollable', value);

            if (value) {
                this.form = this.initForm();
                
                // Initial brand
                this.appendFilterBrand();
            }
        }
    },
    async mounted() {
		await this.initTireBrand();
	},
    destroyed() {
        this.$root.$emit('rootSetContentScrollable', false);
    },
	methods: {
        hide() {
            this.$emit('close');
        },
        initForm() {
			return {
				brands: [],
                isOnlySellerStock: false,
                year: '',
                status: ''
			};
		},
        async initTireBrand() {
            const result = await MasterService.getProductBrand();

			this.brands = Helper.setupOption(result.data);
        },
        appendFilterBrand() {
            this.option.brand.push(this.brands);
            this.option.model.push([]);
            
            this.form.brands.push({
                brand: '',
                model: ''
            });
        },
        removeFilterBrand(index) {
            this.form.brands.splice(index, 1);
            this.option.brand.splice(index, 1);
            this.option.model.splice(index, 1);
        },
        async changeBrand(brand, index) {
            if (brand) {
                let loader = this.$modalLoader.render();
                const result = await MasterService.getProductModel(brand);
                loader.hide();

                // Vue array will not update props if not using splice
                this.option.model.splice(index, 1, Helper.setupOption(result.data));
            } else {
                this.option.model[index] = [];
            }

            this.form.brands[index].model = '';
        },
		downloadExcel() {
			this.$emit('downloadExcel', this.form);
		},
        resetForm() {
			this.form = this.initForm();
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.modal-form {
    max-width: 800px;

    .form {
        .input-field {
            input {
                font-size: 13px;
            }
        }

        .btn {
            font-size: 13px;
			padding: 5px;
			width: 120px;

			.icon {
				font-size: 12px;
				margin-right: 5px;
			}

            &.btn-reset {
				background: #ccc;
			}
		}

        .btn-append {
            background: #fff;
            border: 1px dashed #ccc;
            color: #999;
            font-size: 13px;
            width: 100%;
            height: 36px;
        }

        .btn-remove {
            background: #fff;
            border: none;
            color: #cc0000;
            font-size: 14px;
        }

        .only-seller-stock {
            position: relative;

            .blocker {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                opacity: 0.5;
                background: #fff;
                z-index: 1;
                cursor: not-allowed;
            }
        }
    }
}

::v-deep .form {
	.form-option {
        .dropdown {
            .dropdown-wrapper {
                span {
                    font-size: 14px;
                }
            }
        }

		.input-option {
			font-size: 13px;

			&[readonly] {
				background: #fff;
			}
		}
	}
}
</style>