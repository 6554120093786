<template>
	<div>
		<section class="download">
			<div class="info">
				<h2>จัดการสินค้าครั้งแรก กดปุ่ม > ดาวน์โหลดมาสเตอร์ไฟล์</h2>
				<span class="note">จะได้ไฟล์ Excel ใช้สำหรับเพิ่ม-แก้ไขสินค้าหลายรายการ *หากมีมาสเตอร์ไฟล์อยู่แล้ว สามารถข้ามไป ขั้นตอนที่ 1 ได้เลย</span>
			</div>
			
			<button class="btn btn-main btn-proceed" @click="openModalDownloadFilter()">
				<b-icon icon="cloud-download" class="icon"></b-icon>
				ดาวน์โหลดมาสเตอร์ไฟล์
			</button>
		</section>

		<section class="step" v-if="step === 1">
			<h2>ขั้นตอนที่ 1: แก้ไขและอัพโหลดไฟล์ Excel</h2>
			<span class="note">เมื่อ เพิ่ม-แก้ไขข้อมูล ในมาสเตอร์ไฟล์ Excel เรียบร้อยแล้ว กดปุ่ม "อัพโหลดไฟล์" เพื่ออัพเดทข้อมูลเข้าสู่ระบบ</span>
			<single-upload
				v-model="fileData"
				:classWrapper="'btn btn-warning btn-upload btn-proceed'"
				classError="mt-1 text-danger"
				fileTypeErrorText="โปรดเลือกไฟล์ประเภท xlsx เท่านั้น"
				:fileTypeAllow="['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']">
				<b-icon icon="cloud-upload" class="icon"></b-icon>
				อัพโหลดไฟล์
			</single-upload>
		</section>

		<section class="step" v-if="step === 2">
			<h2>ขั้นตอนที่ 2: ตรวจสอบข้อมูลสต๊อคก่อนการอัพเดต</h2>
			<span class="note">โปรดตรวจสอบรายการสต๊อคที่มีการแก้ไขในตารางด้านล่าง ถ้าหากข้อมูลถูกต้องแล้วให้ทำการกด "ดำเนินการอัพเดตสต๊อค" ถ้ามีข้อมูลไม่ถูกต้องกรุณากดปุ่ม "กลับไปแก้ไข"</span>
			
			<button class="btn btn-danger btn-proceed" @click="proceedUpdate()">
				<b-icon icon="clipboard-check" class="icon"></b-icon>
				ดำเนินการอัพเดตสต๊อค
			</button>
			<button class="btn btn-light-grey btn-proceed" @click="backToUpload()">
                กลับไปแก้ไข
            </button>
		</section>

		<section class="step" v-if="step === 3">
			<h2>ขั้นตอนที่ 3: กำลังทำการอัพเดตสต๊อค</h2>
			<span class="note">โปรดอย่าปิดหน้าจอหรือเปลี่ยนเมนูไปหน้าอื่นในขณะที่กำลังแก้ไขข้อมูลสต๊อคสินค้า</span>
		</section>

		<div class="warning" v-if="step === 2">โปรดตรวจสอบข้อมูลด้านล่างก่อนดำเนินการอัพเดตสต๊อค</div>

		<perfect-scrollbar v-if="step >= 2">
			<table>
				<thead>
					<tr>
						<th></th>
						<th>รหัสสินค้า</th>
						<th>รหัสสินค้า ร้านค้า</th>
						<th>ชื่อสินค้า</th>
						<th>ราคาขาย (ติดตั้ง)</th>
                        <th>ราคาขาย (จัดส่ง)</th>
                        <th>ค่าจัดส่งโซน 1</th>
                        <th>ค่าจัดส่งโซน 2</th>
                        <th>ค่าจัดส่งโซน 3</th>
                        <th>ค่าจัดส่งโซน 4</th>
                        <th>จำนวน</th>
                        <th>สถานะ</th>
                        <th>จำนวนวันเพื่อจัดเตรียมสินค้า</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(item, index) in updateList" :key="index">
						<td class="text-center">
							<loader-spinner
								v-if="item.update.isLoading"
								sizeWidth="20px"
								sizeHeight="20px">
							</loader-spinner>
							
							<span :class="{ 
								'text-danger': !item.update.isSuccess, 
								'text-success': item.update.isSuccess
							}">
								{{ item.update.message }}
							</span>
						</td>
						<td>{{ item.sku }}</td>
						<td>{{ item.sellerSku }}</td>
						<td>{{ item.name }}</td>
						<td>{{ item.price }}</td>
                        <td>{{ item.priceDelivery }}</td>
                        <td>{{ item.deliveryFee1 }}</td>
                        <td>{{ item.deliveryFee2 }}</td>
                        <td>{{ item.deliveryFee3 }}</td>
                        <td>{{ item.deliveryFee4 }}</td>
                        <td>{{ item.stock }}</td>
                        <td>{{ item.status }}</td>
						<td>
							<div class="status-display">
                                สถานะการแสดง: <span class="display" :class="{ 'active': item.isShowOnWeb == 'Yes' }"></span>
                            </div>
                            <br />
                            แบบมีสต๊อค: {{ item.dayPrepareService }}
                            <br />
						</td>
					</tr>
				</tbody>
			</table>
		</perfect-scrollbar>

		<modal-download-file-import
			@close="closeModalDownloadFilter()"
			@downloadExcel="downloadFile($event)"
			:isDisplay="isOpenModalDownloadFilter">
		</modal-download-file-import>
	</div>
</template>

<script>
import Helper from '@/services/helper';
import SellerService from '@/services/sellerService';
import ModalDownloadFileImport from '@/components/seller/stocks/ModalDownloadFileImport';

export default {
	components: {
		ModalDownloadFileImport
	},
	data() {
		return {
			isOpenModalDownloadFilter: false,
			step: 1,
			fileData: null,
			updateList: []
		}
	},
	watch: {
		fileData(curr) {
			this.uploadFile(curr);
		}
	},
	methods: {
		openModalDownloadFilter() {
			this.isOpenModalDownloadFilter = true;
		},
		closeModalDownloadFilter() {
			this.isOpenModalDownloadFilter = false;
		},
		async downloadFile(filter) {
			// Download Excel
			let loader = this.$modalLoader.render();
			const result = await SellerService.downloadExcelFile(filter);
			loader.hide();

			if (result.data?.success) {
				Helper.fileDownloadByApi(result.data?.file);
			} else {
				this.$swal(Helper.warningAlert('ไม่สามารถดาวน์โหลดไฟล์ Excel ได้', result.data?.message));
			}
		},
		async uploadFile(fileData) {
			let loader = this.$modalLoader.render();
			const header = {
				headers: { 'Content-Type': 'multipart/form-data' }
			}
			const formData = new FormData();
			formData.append('excel', fileData);

			const result = await SellerService.uploadExcelFile(formData, header);
			loader.hide();

			if (result.data?.success) {
				this.step = 2;

				const updateList = result.data?.importList.map(obj => ({ ...obj, update: { isLoading: false, isSuccess: false, message: '' } }));

				this.updateList = updateList;
			} else {
				this.$swal(Helper.warningAlert('อัพโหลดไฟล์ไม่สำเร็จ', '', result.data?.message));
			}
		},
		backToUpload() {
            this.step = 1;
        },
		async proceedUpdate() {
			this.step = 3

			for (const item of this.updateList) {
				item.update.isLoading = true;	
				const result = await SellerService.proceedMassUpdate(item);
				item.update.isLoading = false;

				if (result.data?.success) {
					item.update.isSuccess = true;
					item.update.message = 'สำเร็จ!';
				} else {
					item.update.message = result.data?.message;
				}
			}
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.download {
	background: #f2f2f2;
	padding: 20px;
	margin-bottom: 30px;
	display: flex;
	justify-content: space-between;

	.info {
		width: 70%;

		h2 {
			margin: 0;
			color: #000;
			font-size: 18px;
		}

		.note {
			display: block;
			text-align: left;
			font-size: 13px;
			color: #666;
		}
	}

	.btn-proceed {
		padding: 10px;
		display: block;
		font-size: 13px;

		.icon {
			margin-right: 15px;
		}
	}
}

.step {
	text-align: center;
    margin: 15px auto;
    max-width: 500px;

	h2 {
		text-align: left;
		margin: 0;
		color: #666;
		font-size: 18px;
		margin-bottom: 15px;

		&:after {
			content: " ";
			display: block;
			width: 100px;
			height: 1px;
			background: #ccc;
			margin: 10px 0 0;
		}
	}

	.note {
		display: block;
		margin-bottom: 20px;
		text-align: left;
		font-size: 13px;
		color: #999;
	}

	.btn-proceed {
		padding: 10px;
		width: 100%;
		display: block;

		.icon {
			margin-right: 15px;
		}

		& + .btn-proceed {
			margin-left: 0;
			margin-top: 5px;
		}
	}
}

.warning {
	font-size: 13px;
	text-align: center;
	color: #c37575;
	margin: 20px 0;
}

table {
	width: 100%;
	font-size: 13px;
	line-height: 15px;

	thead {
		th {
			background: #eee;
			padding: 13px 10px;
		}
	}

	tbody {
		td {
			border-bottom: 1px solid #999;
			padding: 10px;
			
			.status-display {
				@extend .sellerProductStatusDisplay;
				
				.display {
					margin-left: 5px;
					margin-top: 2px;
				}
			}
		}
	}
}
</style>